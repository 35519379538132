export const ROUTES = {
  ROOT: '/',
  // Navigation
  SETTINGS: '/settings',
  DASHBOARD: '/dashboard',
  ONBOARDING_NEW: '/onboarding/new',
  UNDERWRITING: '/underwriting',
  UNDERWRITING_NEW: '/underwriting/new',
  UNDERWRITING_REQUESTS: '/underwriting/requests',
  UNDERWRITING_REQUEST: '/underwriting/:executionId',
  UNDERWRITING_ANONYMOUS_EXECUTION_REQUEST:
    '/underwriting/anonymous/e/:executionId',
  UNDERWRITING_ANONYMOUS_FLOW_REQUEST: '/underwriting/anonymous/f/:flowId',
  LOAN_DETAILS: '/servicing/loans/details',
  LOAN_EDIT_CONTACT: '/servicing/loans/contact',
  LOAN_GROUP: '/servicing/loans/group',
  LOAN_REPAYMENTS: '/servicing/loans/repayments',
  LOAN_REPAYMENTS_EDIT: '/servicing/loans/repayments/edit',
  LOAN_REPAYMENTS_GROUPED: '/servicing/loans/group/repayments',
  LOAN_PAYMENT: '/servicing/loans/payment',
  LOAN_EDIT: '/servicing/loans/edit',
  PAYOFF: '/servicing/loans/payoff',
  REPORTS: '/servicing/loans/reports',
  DOCUMENTATION: '/servicing/loans/documentation',
  MANAGE_DOCUMENTATION: '/servicing/manage-documentation',
  MANAGE_INVESTMENTS: '/servicing/manage-investments',
  MANAGE_INVESTMENTS_EDIT: '/servicing/manage-investments/edit',
  INDIVIDUAL: '/individual',
  PORTFOLIO: '/portfolio',
  SERVICING_LOANS: '/servicing/loans',
  SERVICING_TASKS: '/servicing/tasks',
  SERVICING_REPAYMENTS: '/servicing/repayments',
  SERVICING_TRANCHES: '/servicing/loans/tranches',
  SERVICING_TRANSACTIONS: '/servicing/transaction',
  SERVICING_RISK_ANALYSIS: '/servicing/risk-analysis',
  SERVICING_RISK_REPORT: '/servicing/risk-analysis/report',
  INVESTORS: '/servicing/investors',
  CUSTOM_INFORMATION: '/servicing/loans/custom-information',
  REPORTING: '/reporting',
  REPAYMENT_SCHEDULES: '/schedules/repayments',
  NOTIFICATIONS: '/notifications',
  COMING_SOON: '/coming-soon',
  // Login
  SIGN_IN: '/sign-in',
  PASSWORD_RECOVERY: '/sign-in/password-recovery',
  FIRST_LOGIN: '/first-login',
  // Demo Originator
  DIRECT_DEBIT: '/direct-debit',
  TASKS: '/tasks',
  LOAN_TRANSACTION: '/transactions',
  SCHEDULES: '/schedules',
  DOCUMENTS: '/documents',
  BORROWER_DOCUMENTATION: '/documentation',
  USERS: '/users',
  TRANCHES: '/tranches',
  DIRECT_DEBIT_SETUP: '/direct-debit-setup',
  NOT_FOUND: '/404',
} as const;
