import React, { ReactNode, ReactPortal, memo } from 'react';
import classNames from 'classnames';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import style from './Dropdown.module.css';

export interface DropDownItemType<_A = void, TRole extends string = string> {
  icon?: ReactNode;
  text: string;
  name?: string;
  permissions?: TRole[];
  types?: string[];
  onClickHandler?: (val: unknown) => void;
}

interface DropDownProps<_A = void> extends React.ComponentProps<React.FC> {
  anchor?: 'right' | 'middle' | 'none';
  children?: React.ReactNode;
  button: React.ReactElement;
  dropDownItems?: DropDownItemType[];
  hoverColor?: 'primary' | 'neutral' | 'none';
  separator?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'fit';
  textClasses?: string;
  alignOffset?: number;
  align?: 'start' | 'center' | 'end';
  side?: 'top' | 'right' | 'bottom' | 'left';
}

const DropDown = <A,>({
  anchor = 'right',
  button,
  children,
  dropDownItems = [],
  hoverColor = 'primary',
  separator = true,
  size = 'lg',
  alignOffset = 15,
  align = 'end',
  side = 'bottom',
}: DropDownProps<A>) => {
  const items = dropDownItems;
  const childs = React.Children.toArray(children);
  const childrenCount = React.Children.count(children);

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger className="z-100 flex items-center focus:outline-none">
        {button}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        loop
        className={classNames(
          'origin-top-right border border-neutral-200 rounded-md drop-shadow-avatar bg-white focus:outline-none z-[100] shadow-snackbar',
          style.container,
          dropDownWidth[size],
          {
            'right-0': anchor === 'right',
            'right-4 top-7': anchor === 'middle',
          }
        )}
        align={align}
        side={side}
        alignOffset={alignOffset}
        hideWhenDetached
      >
        {childrenCount > 0
          ? childs.map((child, index) => (
              <Item
                key={`dropdown${index}`}
                hoverColor={hoverColor}
                isChild
                isLastItem={childs[index + 1] !== undefined}
                item={child as ReactPortal}
                separator={separator}
              />
            ))
          : items.map((item, index) => (
              <Item
                key={`dropdown${item.text}`}
                hoverColor={hoverColor}
                isLastItem={items[index + 1] !== undefined}
                item={item}
                separator={separator}
              />
            ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default memo(DropDown);

const Item = ({
  hoverColor = 'primary',
  isLastItem,
  isChild = false,
  item,
  separator,
}: {
  hoverColor?: 'primary' | 'neutral' | 'none';
  isLastItem: boolean;
  isChild?: boolean;
  item: DropDownItemType | ReactPortal;
  separator: boolean;
}) => {
  return (
    <DropdownMenu.Item className="focus:outline-none">
      <div
        {...(!isChild &&
          DropdownTypeGuard(item) && { onClick: item?.onClickHandler })}
        className={classNames(
          'flex items-center rounded-md focus:outline-none z-[100] cursor-pointer',
          hoverItemColor[hoverColor],
          { 'p-3': !isChild }
        )}
      >
        {isChild
          ? (item as React.ReactNode)
          : DropdownTypeGuard(item) && (
              <>
                {item?.icon && <div className="w-5 mr-1">{item?.icon}</div>}
                {item?.text && (
                  <div className={classNames('body-400 inline-block z-50')}>
                    {item?.text}
                  </div>
                )}
              </>
            )}
      </div>
      {isLastItem && separator && (
        <div className="border-neutral-400 border-t mx-4"></div>
      )}
    </DropdownMenu.Item>
  );
};

const dropDownWidth = {
  fit: '',
  sm: 'w-28',
  md: 'w-44',
  lg: 'w-60',
  xl: 'w-[459px]',
};

const hoverItemColor = {
  primary: 'hover:bg-primary-100',
  neutral: 'hover:bg-neutral-200',
  none: '',
};

const DropdownTypeGuard = (
  item: DropDownItemType | ReactPortal
): item is DropDownItemType => {
  return (item as DropDownItemType).text !== undefined;
};
