import React from 'react';
import { LABELS } from '@/constants/productTypeDictionary';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import useInterestCapitalisationFrequencyValue from '@/hooks/useInterestCapitalisationFrequency';
import { ProductComponentVisibility } from '@/hooks/useProductComponentConfiguration';
import { useGuardedGetLoanProductRulesQuery } from '@/services/loans';
import { formatAmount, formatDate, formatPercent } from '@/utils/formatters';
import { getSingularOrPluralInstallments } from '@/utils/helpers';
import { useGetLoanDetailsConfiguration } from '../hooks/useGetLoanDetailsConfiguration';
import { CalculationMethodDetails } from './CalculationMethodDetails';
import { InvestorDetails } from './InvestorDetails';
import { PrincipalCollectionFrequencyDetails } from './PrincipalCollectionFrequencyDetails';
import SummaryDetail from './Summary';
import { IndexInterestRateTypeDto, Loan } from '@/interfaces/loans';
import { type LoanSummary } from '@/interfaces/loans/queries';

const LoanDetail = ({
  loan,
  loanSummary,
  productType,
  loanDetailsConfig,
}: {
  loan: Loan;
  loanSummary: LoanSummary;
  productType?: string;
  loanDetailsConfig?: ProductComponentVisibility;
}) => {
  const {
    interestType,
    loanAmountLabel,
    deductedInterestLabel,
    loanAmountTooltip,
    configurationProductData,
  } = useGetLoanDetailsConfiguration(loan);
  const { loansUpper } = useGetLabelsConfig();

  const { currentData: loanProductRules } = useGuardedGetLoanProductRulesQuery(
    loanSummary?.productTypeKey,
    loanSummary?.availabilitySettings
  );

  const interestCapitalisationFrequencyValue =
    useInterestCapitalisationFrequencyValue(loan, loanProductRules);

  const factorLoan = !!loanProductRules?.factorLoan;

  const isInterestRateIndexRate =
    loan?.interestSettings?.indexRateType === IndexInterestRateTypeDto.INDEX;

  const showGrace = configurationProductData?.grace?.interestGrace
    ? loanDetailsConfig?.showInterestPeriodsRetained
    : loanDetailsConfig?.showInterestGrace;

  const showInterestDetails = isInterestRateIndexRate
    ? loanDetailsConfig?.showSpreadInterest
    : loanDetailsConfig?.showInterestRate;
  if (!loan) return;
  return (
    <>
      <SummaryDetail
        title={`${loansUpper} type`}
        detail={loanSummary?.type}
        show={loanSummary?.type && loanDetailsConfig.showLoanType}
      />

      <SummaryDetail
        title={`${loansUpper} name`}
        detail={loanSummary?.loanName}
        show={loanDetailsConfig?.showLoanName}
      />

      <SummaryDetail
        title="Base index rate"
        detail={loanSummary?.baseIndexRate ?? ''}
        show={
          loanDetailsConfig?.showBaseInterestRate && isInterestRateIndexRate
        }
      />

      <SummaryDetail
        title="Interest rate"
        detail={formatPercent(loanSummary?.displayedInterestRate, 2)}
        show={showInterestDetails && !factorLoan}
      />

      <SummaryDetail
        title="Spread interest"
        detail={formatPercent(loan?.interestSettings?.interestSpread, 2)}
        show={showInterestDetails && !factorLoan && isInterestRateIndexRate}
      />

      <CalculationMethodDetails
        loanSummary={loanSummary}
        loanDetailsConfig={loanDetailsConfig}
      />

      <InvestorDetails
        loanSummary={loanSummary}
        loanDetailsConfig={loanDetailsConfig}
      />

      <SummaryDetail
        title={loanAmountLabel}
        detail={formatAmount(
          loanSummary?.totalAmount,
          true,
          loanSummary?.currency
        )}
        questionTooltip={loanAmountTooltip}
        show={loanDetailsConfig?.showLoanAmount}
      />

      <SummaryDetail
        title="First disbursement date"
        detail={formatDate(loanSummary.firstHistoricalDisbursementDate)}
        show={loanDetailsConfig?.showDisbursementDate}
      />

      <SummaryDetail
        title="Total Repayment Amount"
        detail={formatAmount(
          loanSummary?.totalRepaymentAmount,
          true,
          loanSummary?.currency
        )}
        show={factorLoan && loanDetailsConfig?.showRepaymentAmount}
      />

      {loanDetailsConfig?.showFee &&
        loanSummary?.feesSummary?.map((fee) => {
          const overwritedTitle =
            fee.name === 'Deducted Interest' ? deductedInterestLabel : fee.name;
          return (
            <SummaryDetail
              key={fee.name}
              title={overwritedTitle}
              detail={formatAmount(fee.amount, true, loanSummary?.currency)}
            />
          );
        })}

      <SummaryDetail
        title="Interest type"
        detail={interestType}
        show={
          interestType && !factorLoan && loanDetailsConfig?.showInterestType
        }
      />

      <SummaryDetail
        title="Investor interest commission"
        detail={`${loan?.interestSettings?.investorInterest}%`}
        show={
          !!loan?.interestSettings?.investorInterest &&
          loanDetailsConfig?.showInvestorInterest
        }
      />

      <SummaryDetail
        title={LABELS?.[productType]?.repaymentInstallments}
        detail={loanSummary?.repaymentInstallments}
        show={loanDetailsConfig?.showRepaymentInstalments}
      />

      <SummaryDetail
        title="Roll-up duration "
        detail={`${
          loan?.rollUpDuration ?? 0
        } ${getSingularOrPluralInstallments(loan?.rollUpDuration ?? 0)}`}
        show={
          (loanProductRules?.rollUpDuration ||
            loanProductRules?.rollUpDuration2) &&
          loanDetailsConfig?.showRollupDuration
        }
      />

      <SummaryDetail
        title="Repaid every"
        detail={`${loan?.scheduleSettings?.repaymentPeriodCount} ${loan?.scheduleSettings?.repaymentPeriodUnits}`}
        show={
          loan?.scheduleSettings?.repaymentPeriodCount > 0 &&
          loan?.scheduleSettings?.repaymentPeriodUnits &&
          loanDetailsConfig?.showRepaymentFrequency
        }
      />

      <SummaryDetail
        title="Interest capitalisation frequency"
        detail={interestCapitalisationFrequencyValue}
        show={
          interestCapitalisationFrequencyValue &&
          loanDetailsConfig?.showInterestCapitalisationFrequency
        }
      />

      <PrincipalCollectionFrequencyDetails
        loanSummary={loanSummary}
        loanDetailsConfig={loanDetailsConfig}
      />

      <SummaryDetail
        title="Minimum interest period"
        detail={`${loan?.minimumInterestPeriod ?? 0} instalments`}
        show={
          loanProductRules?.minimumInterestPeriod &&
          loanDetailsConfig?.showMinimumInterestPeriod
        }
      />

      <SummaryDetail
        title={`${
          configurationProductData?.grace?.interestGrace
            ? 'Interest periods retained'
            : 'Interest grace period'
        }`}
        detail={`${
          loan?.interestSettings?.interestGraceInstallments
        } ${getSingularOrPluralInstallments(
          loan?.interestSettings?.interestGraceInstallments
        )}`}
        show={
          (!!loan?.interestSettings?.interestGraceInstallments ||
            loan?.interestSettings?.interestGraceInstallments === 0) &&
          showGrace
        }
      />

      <SummaryDetail
        title="Periodic payment amount"
        detail={formatAmount(
          loan?.scheduleSettings?.periodicPaymentAmount,
          true,
          loanSummary?.currency
        )}
        show={loan?.scheduleSettings?.periodicPaymentAmount > 0}
      />

      <SummaryDetail
        title="Balloon payment %"
        detail={`${loan?.scheduleSettings?.balloonPaymentPercentage * 100} %`}
        show={loan?.scheduleSettings?.balloonPaymentPercentage > 0}
      />

      <SummaryDetail
        title="Balloon payment amount"
        detail={formatAmount(
          loan?.scheduleSettings?.balloonPaymentAmount,
          true,
          loanSummary?.currency
        )}
        show={loan?.scheduleSettings?.balloonPaymentAmount > 0}
      />

      <SummaryDetail
        title="Grace period"
        detail={loan?.gracePeriod}
        show={loanDetailsConfig?.showGracePeriod}
      />

      <SummaryDetail
        title="Grace amount"
        detail={`${loan?.graceAmount || 0}%`}
        show={loanDetailsConfig?.showGraceAmount}
      />

      <SummaryDetail
        title={LABELS[productType].monthlyRepaymentDay}
        detail={loanSummary?.monthlyRepaymentDay}
        show={
          !!loanSummary?.monthlyRepaymentDay &&
          loanDetailsConfig.showMonthlyRepaymentDay
        }
      />

      <SummaryDetail
        title="Penalty rate"
        detail={`${loan?.penaltyRate}%`}
        show={loan?.penaltyRate > 0 && loanDetailsConfig?.showPenaltyRate}
      />

      <SummaryDetail
        title="Exit fee"
        detail={formatAmount(loan?.exitFeeAmount, true, loanSummary?.currency)}
        show={
          loanProductRules?.exitFeeEnabled && loanDetailsConfig?.showExitFee
        }
      />
    </>
  );
};

export default LoanDetail;
