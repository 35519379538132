import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/navigation';
import { cloneDeep, debounce, orderBy } from 'lodash';
import { ROUTES } from '@/constants/routes';
import { SNACKBAR_UNDERWRITING } from '@/constants/snackbar-messages';
import { useSnackbar } from '@/hooks/useSnackbar';
import {
  useAddPagesToExecutionMutation,
  useCreateExecutionMutation,
  useCreateOrUpdateDatastoreMutation,
  useLazyGetDatastoreQuery,
} from '@/services/underwriting';
import {
  AddPagesToExecution,
  AvailableUaaSContext,
  Datapoint,
  Execution,
  Flow,
  ModuleRef,
} from '@/store/uaas/types';
import { useAppDispatch, useTypedSelector } from '../hooks';
import { UaaSContext } from './const';
import { setExecution, setFlow, setProductId } from './slice';
import { getCompletedPagesUntilNow } from './uaasFunctions';
import { useGetUaasData } from './uaasGetDataHook';
import { useGetExecution } from './useGetExecution';
import { CreateUnderwritingBorrowerDto } from 'kennek/interfaces/accounts';

const pendingDataStoreRequests: ((executionId: string) => Promise<void>)[] = [];

interface Props {
  executionId?: string;
  loadOnlyWaitingPage?: boolean;
  children: React.ReactNode;
}

const UaasProvider = ({
  children,
  executionId: executionIdProp,
  loadOnlyWaitingPage,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedPageId, setSelectedPageId] = useState('');
  const [isRootSpinnerUnlocked, setIsRootSpinnerUnlocked] = useState(true);
  const [completedPages, setCompletedPages] = useState<string[]>([]);

  const [executionId, setExecutionId] = useState(executionIdProp);
  const [isLoading, setIsLoading] = useState(true);

  const [lazyGetDatastore] = useLazyGetDatastoreQuery();
  const [createOrUpdateDatastore] = useCreateOrUpdateDatastoreMutation();
  const [addPagesToExecution] = useAddPagesToExecutionMutation();
  const [createExecution] = useCreateExecutionMutation();
  const modulesRef = useRef<ModuleRef[]>([]);

  const router = useRouter();
  const snackbar = useSnackbar();
  const loanDetails = { borrowerEmail: '', loanId: '', migrationFailed: false };
  const dispatch = useAppDispatch();
  const flow = useTypedSelector((state) => state.uaas.flow) as Flow;
  const productId = useTypedSelector((state) => state.uaas.productId);

  const {
    executionQuery,
    flowQuery,
    pagesQuery,
    flowPagesQuery,
    modulesQuery,
    flowModulesQuery,
    currentPageId,
    pages,
    isDashboard,
    isLoadingData,
    isLoadingModule,
    uniquePageQuery,
  } = useGetUaasData({
    executionId,
    flowId: flow?.id,
    selectedPageId,
    loadOnlyWaitingPage,
  });

  const uniquePage = uniquePageQuery?.currentData;
  const setCompletePage = (pageId: string) => {
    setCompletedPages([...completedPages, pageId]);
  };

  const sortedPages = useMemo(() => {
    const sorted = cloneDeep(pages).sort((a, b) =>
      a.order >= b.order ? 1 : -1
    );
    return sorted.map((page) => {
      const isComplete = !!completedPages.find((pageId) => pageId === page.id);
      return {
        ...page,
        completed: isComplete,
      };
    });
  }, [pages, currentPageId, completedPages]);

  const selectNextPage = (pageId: string, actions: string[]) => {
    const pageIndex = sortedPages.findIndex((data) => data.id === pageId);
    let step = 0;
    if (actions.includes('CONTINUE')) {
      step = 1;
    } else if (actions.includes('REVERSE')) {
      step = -1;
    }

    if (actions.includes('SAVE') && !actions.includes('CONTINUE')) {
      snackbar.show({
        severity: 'primary',
        title: SNACKBAR_UNDERWRITING.SAVE_AS_DRAFT,
      });
    }

    const nextPage = sortedPages[pageIndex + step];
    const nextPageId = nextPage ? nextPage.id : '';
    setSelectedPageId(nextPageId);
  };

  const { isLoadingTransferData, executeAction } = useGetExecution({
    setCompletePage,
    modulesRef,
    loanDetails,
    pagesQuery,
    executionQuery,
    selectNextPage,
  });
  const execution = executionQuery.currentData;
  const executionCurrentPageId = execution?.currentPageId;

  const currentPageConfig = useMemo(() => {
    return (pagesQuery?.currentData ?? flowPagesQuery?.currentData)?.find(
      (page) => page.id === currentPageId
    );
  }, [currentPageId, pagesQuery?.currentData, flowPagesQuery?.currentData]);

  useEffect(() => {
    if (!isLoadingData && !currentPageConfig) pagesQuery?.refetch();
  }, [currentPageConfig, isLoadingData]);

  const setLoader = debounce((val: boolean) => {
    setIsLoading(val);
  }, 300);

  useEffect(() => {
    const isLoad =
      isRootSpinnerUnlocked && (isLoadingData || isLoadingTransferData);
    setLoader(isLoad);
  }, [isRootSpinnerUnlocked, isLoadingData, isLoadingTransferData]);

  useEffect(() => {
    if (!executionCurrentPageId || !pages?.length) return;
    dispatch(setExecution(execution));
    const completedPagesId = getCompletedPagesUntilNow(
      executionCurrentPageId,
      sortedPages
    );
    setCompletedPages(completedPagesId);
  }, [executionCurrentPageId, pages]);

  useEffect(() => {
    if (!execution?.currentPageId) return;
    setSelectedPageId(execution?.currentPageId);
  }, [execution?.currentPageId]);

  useEffect(() => {
    if (!pagesQuery.isUninitialized) pagesQuery?.refetch();
  }, [execution?.currentStage, pagesQuery.isUninitialized]);

  useEffect(() => {
    if (executionIdProp) loadExecution(executionIdProp);
  }, []);

  const pageModules = useMemo(
    () =>
      orderBy(
        modulesQuery?.currentData ?? flowModulesQuery?.currentData ?? [],
        ['order'],
        ['asc']
      ),
    [modulesQuery?.currentData, flowModulesQuery?.currentData]
  );

  const getModuleRef = (index: number) => (ref: ModuleRef) => {
    modulesRef.current[index] = ref;
  };

  const loadExecution = async (executionId: string) => {
    setExecutionId(executionId);
  };

  const loadFlow = async (
    flowId: string,
    productId?: string
  ): Promise<Flow> => {
    const flow = { id: flowId } as Flow;
    dispatch(setFlow(flow));
    if (productId) {
      dispatch(setProductId(productId));
    }

    return flow;
  };

  const saveToDatastore = async (
    moduleKey: string,
    key: Datapoint
  ): Promise<void> => {
    const getCreateOrUpdate = (executionId: string) =>
      new Promise<void>((res, rej) => {
        createOrUpdateDatastore({
          executionId,
          prefix: moduleKey,
          keyValues: key,
        })
          .unwrap()
          .then(() => res())
          .catch(() => {
            snackbar.show({
              severity: 'error',
              title: SNACKBAR_UNDERWRITING.CHANGES_SAVE_FAILED,
            });
            rej();
          });
      });

    if (!executionId) {
      pendingDataStoreRequests.push(getCreateOrUpdate);
      return;
    }

    return getCreateOrUpdate(executionId);
  };

  const retrieveFromDatastore = async (
    moduleKey: string,
    key: (keyof Datapoint)[]
  ): Promise<Datapoint> => {
    return (
      executionId &&
      lazyGetDatastore({
        executionId,
        prefix: moduleKey,
        keys: key,
      }).unwrap()
    );
  };

  const pageIsComplete: AvailableUaaSContext['pageIsComplete'] = async ({
    pageId,
    actions,
    buttonRef,
  }: {
    pageId: string;
    actions: string[];
    buttonRef: string;
  }): Promise<void> => {
    setIsRootSpinnerUnlocked(false);
    setIsSubmitting(true);
    // If there is no execution at action, but flow is given, create a new execution
    let newExecutionFromFlow: Execution;
    if (!executionId && flow?.id) {
      newExecutionFromFlow = await createExecution({
        body: { flowId: flow?.id, productId: productId },
      }).unwrap();
    }

    const retrieveCreateBorrowerData = (await retrieveFromDatastore(
      'create-borrower-user',
      ['email', 'firstName', 'lastName', 'fullName']
    )) as unknown as CreateUnderwritingBorrowerDto;

    const currentExecutionId = executionId ?? newExecutionFromFlow?.id;
    const isSaved = actions.includes('SAVE')
      ? await executeAction({
          pageId,
          action: 'SAVE',
          executionId: currentExecutionId,
          buttonRef,
          isOriginatorEditPageFilledByBorrower:
            uniquePage?.reviewConfig?.allowEditByOriginator,
        }).catch(() => {
          setIsSubmitting(false);
        })
      : true;
    const createNoInviteBorrowerData: CreateUnderwritingBorrowerDto = {
      firstName: retrieveCreateBorrowerData?.firstName,
      lastName: retrieveCreateBorrowerData?.lastName,
      fullName: retrieveCreateBorrowerData?.fullName,
      email: retrieveCreateBorrowerData?.email,
      executionId: currentExecutionId,
    };

    if (actions.includes('CREATE_BORROWER')) {
      await executeAction({
        pageId,
        action: 'CREATE_BORROWER',
        executionId: currentExecutionId,
        buttonRef,
        createNoInviteBorrowerData,
      }).catch(() => {
        loanDetails.migrationFailed = true;
      });
    }
    if (actions.includes('MIGRATE')) {
      if (createNoInviteBorrowerData && !loanDetails.migrationFailed) {
        await executeAction({
          pageId,
          action: 'MIGRATE',
          executionId: currentExecutionId,
          buttonRef,
          createNoInviteBorrowerData,
        }).catch(() => {});
      } else {
        await executeAction({
          pageId,
          action: 'MIGRATE',
          executionId: currentExecutionId,
          buttonRef,
        }).catch(() => {});
      }
    }

    // If save action failed, we should not call "CONTINUE" action
    if (!isSaved) {
      setIsSubmitting(false);
      setIsRootSpinnerUnlocked(true);
      return;
    }

    // If no execution was present at data store updates - call all pending
    if (pendingDataStoreRequests.length > 0) {
      await Promise.all(
        pendingDataStoreRequests.map((p) => p(currentExecutionId))
      );
      pendingDataStoreRequests.splice(0, pendingDataStoreRequests.length);
    }

    try {
      const promises = [];
      await actions
        .filter(
          (a) => a !== 'SAVE' && a !== 'MIGRATE' && a !== 'CREATE_BORROWER'
        )
        .reduce(
          (acc, action) =>
            acc.then(() =>
              promises.push(
                executeAction({
                  pageId,
                  action,
                  executionId: currentExecutionId,
                  buttonRef,
                })
              )
            ),
          Promise.resolve()
        );

      Promise.all(promises)
        .then(() => {
          selectNextPage(pageId, actions);
        })
        .catch(() => {})
        .finally(() => {
          setIsSubmitting(false);
          setIsRootSpinnerUnlocked(true);
        });
    } catch {
      setIsSubmitting(false);
      setIsRootSpinnerUnlocked(true);
    }

    if (newExecutionFromFlow) {
      setExecutionId(currentExecutionId);

      router.push(
        ROUTES.UNDERWRITING_ANONYMOUS_EXECUTION_REQUEST.replace(
          ':executionId',
          currentExecutionId
        )
      );
    }
  };

  const addNewPages: AvailableUaaSContext['addNewPages'] = async (
    newPagesRequest: AddPagesToExecution
  ): Promise<void> => {
    setIsSubmitting(true);
    return addPagesToExecution({
      executionId,
      currentPageId: newPagesRequest.currentPageId,
      newPages: newPagesRequest.newPages,
    })
      .unwrap()
      .then(() => {
        snackbar.show({
          severity: 'primary',
          title: SNACKBAR_UNDERWRITING.CHANGES_SAVE_SUCCESS,
          duration: 2000,
        });
      })
      .catch(() => {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_UNDERWRITING.CHANGES_SAVE_FAILED,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const values = {
    pages,
    sortedPages,
    pageModules,
    currentPageId,
    currentPageConfig,
    modulesQuery,
    uniquePage,

    loadExecution,
    loadFlow,
    isDashboard,
    saveToDatastore,
    retrieveFromDatastore,
    setSelectedPageId,
    pageIsComplete,
    setCompletePage,
    pendingDataStoreRequests,
    getModuleRef,
    addNewPages,
    isSubmitting,
    isLoading,
    isLoadingModule,
    execution: executionQuery?.currentData && {
      ...executionQuery?.currentData,
    },
    executionId: executionQuery?.currentData?.id,
    flow: flowQuery?.currentData && { ...flowQuery?.currentData },
  };

  return <UaaSContext.Provider value={values}>{children}</UaaSContext.Provider>;
};

export { UaasProvider };

export const useUaasContext = () => {
  const context = useContext(UaaSContext);
  if (!context)
    throw new Error('UaaSContext must be used within a UserProvider');
  return context;
};
