import React from 'react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import DropDown from './Dropdown/DropDown';
import type { DropDownItemType } from './Dropdown/DropDown';

interface Props {
  dropDownItems: DropDownItemType[];
}

const KebabButton: React.FC<Props> = ({ dropDownItems = [] }) => {
  return (
    <DropDown
      button={
        <div className="text-secondary-400 rounded-full p-2 hover:bg-neutral-200 hover:bg-opacity-40">
          <DotsVerticalIcon width={20} />
        </div>
      }
      dropDownItems={dropDownItems}
    />
  );
};

export default KebabButton;
