import React, { useEffect, useMemo, useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { SvgIcon } from 'kennek/icons';
import { debounce } from 'lodash';
import { PlusCircleIcon, SwitchHorizontalIcon } from '@heroicons/react/outline';
import { ROUTES } from '@/constants/routes';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { ProductComponentVisibility } from '@/hooks/useProductComponentConfiguration';
import { useRole } from '@/hooks/useRole';
import { getRouterQuery } from '@/utils/helpers';
import { CurrentBalanceWidgetNavigation } from './CurrentBalanceWidgetNavigation';
import BalanceWidgetResolver from './balance-widget/resolver/BalanceWidgetResolver';
import { UserTypes } from 'kennek/interfaces/accounts';
import { OptionalFeatures } from 'kennek/interfaces/kennek';
import { LoanProductRules, ProductTypesNames } from '@/interfaces/loans';
import type { LoanSummary } from '@/interfaces/loans/queries';

export interface Props extends React.ComponentProps<React.FC> {
  applyManualFeeEnabled?: boolean;
  hasManualFee?: boolean;
  loanBalanceConfig?: ProductComponentVisibility;
  interestRateEditionEnabled?: boolean;
  isWrittenOffLoan?: boolean;
  loanId?: string;
  loanSummary: LoanSummary;
  openApplyFeeModal?: () => void;
  openInterestRateModal?: () => void;
  openRateHistoryModal?: () => void;
  productRules?: LoanProductRules;
  type?: string;
  variant: 'servicing' | 'borrower';
  disabledFeatures?: OptionalFeatures[];
}

const CurrentBalanceWidget: React.FC<Props> = ({
  applyManualFeeEnabled,
  hasManualFee,
  loanBalanceConfig,
  interestRateEditionEnabled,
  isWrittenOffLoan,
  loanId,
  loanSummary,
  openInterestRateModal,
  openRateHistoryModal,
  openApplyFeeModal,
  productRules,
  type,
  variant,
  disabledFeatures,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { role, isBorrower, isInvestor } = useRole();
  const searchParams = useSearchParams();
  const loanIdQuery = searchParams.get('loanId');
  const { loanLower } = useGetLabelsConfig();

  const permissions = ROLES_PERMISSIONS[role];
  const router = useRouter();

  const principalAndInterest = useMemo(
    () =>
      productRules?.interestBalanceCalculationMethod ===
      'PRINCIPAL_AND_INTEREST',
    [productRules]
  );

  const decliningBalance = useMemo(
    () =>
      productRules?.interestCalculationMethod ===
      'DECLINING_BALANCE_DISCOUNTED',
    [productRules]
  );

  const setLoader = debounce((val: boolean) => {
    setIsLoading(val);
  }, 2000);

  const isNullOrUndefined = (val: boolean): boolean =>
    val === undefined || val === null;

  useEffect(() => {
    const isLoadingData =
      isNullOrUndefined(principalAndInterest) ||
      isNullOrUndefined(decliningBalance);

    setLoader(isLoadingData);
  }, [principalAndInterest, decliningBalance, setLoader]);

  const factorLoan = productRules?.factorLoan;
  const rateHistory = productRules?.interestRateEditionEnabled;

  const shouldShowApprovedLoanNavigationItems =
    variant === 'servicing' &&
    permissions?.includes('PAY_OFF/ADD_DOCUMENTATION/VIEW_TRANSACTIONS') &&
    loanSummary?.state === 'APPROVED';

  const shouldShowViewTransactionNavigationItem =
    variant === 'borrower' && loanSummary?.state === 'APPROVED';

  const shouldShowRateHistoryNavigationItem =
    (isBorrower || isInvestor) &&
    loanSummary?.state === 'APPROVED' &&
    rateHistory;

  const shouldShowAddFeeNavigationItem =
    (role === 'ORIGINATOR_ADMIN' || role === 'ORIGINATOR_ANALYST') &&
    [
      ProductTypesNames.DYNAMIC_TERM_LOAN,
      ProductTypesNames.REVOLVING_CREDIT,
    ].includes(productRules?.productType);

  const shouldShowLoanApplicationNavigationItem =
    !!loanSummary?.executionId &&
    !disabledFeatures?.includes(OptionalFeatures.UNDERWRITING);

  const loanLocks = loanSummary?.locks;

  if (!loanBalanceConfig) return;

  return (
    <BalanceWidgetResolver
      decliningBalance={decliningBalance}
      loanBalanceConfig={loanBalanceConfig}
      isFactorLoan={!!factorLoan}
      isWrittenOffLoan={isWrittenOffLoan}
      loanSummary={loanSummary}
      principalAndInterest={principalAndInterest}
      type={type}
      isLoading={isLoading}
      openRepaymentSchedule={() => {
        const path = getRouterQuery(
          isBorrower ? ROUTES.REPAYMENT_SCHEDULES : ROUTES.LOAN_REPAYMENTS,
          {
            loanId,
          }
        );
        router.push(path);
      }}
    >
      <CurrentBalanceWidgetNavigation
        maxVisibilityWidth={650}
        items={[
          {
            display:
              shouldShowApprovedLoanNavigationItems && !loanLocks?.payments,
            icon: <SvgIcon name="PayOffIcon" className="w-5 h-5 mt-2" />,
            label: `Pay off ${loanLower}`,
            onClick: () => {
              const path = getRouterQuery(ROUTES.PAYOFF, {
                loanId: loanIdQuery,
              });
              router.push(path);
            },
          },
          {
            display: shouldShowApprovedLoanNavigationItems,
            icon: <SvgIcon name="DocumentsIcon" className="w-5 h-5" />,
            label: 'Add documentation',
            onClick: () => {
              const path = getRouterQuery(ROUTES.MANAGE_DOCUMENTATION, {
                loanId: loanIdQuery,
              });
              router.push(path);
            },
          },
          {
            display:
              shouldShowViewTransactionNavigationItem ||
              shouldShowApprovedLoanNavigationItems,
            icon: <SwitchHorizontalIcon className="w-5 h-5" />,
            label: 'View transactions',
            onClick: () => {
              if (shouldShowViewTransactionNavigationItem) {
                return router.push(ROUTES.LOAN_TRANSACTION);
              }

              const path = getRouterQuery(ROUTES.SERVICING_TRANSACTIONS, {
                loanId,
              });
              router.push(path);
            },
          },
          {
            display:
              shouldShowApprovedLoanNavigationItems &&
              interestRateEditionEnabled &&
              !loanLocks?.edit,
            icon: <SvgIcon name="EditInterestRateIcon" className="w-5 h-5" />,
            label: 'Edit interest rate',
            onClick: openInterestRateModal,
          },
          {
            display:
              shouldShowApprovedLoanNavigationItems &&
              applyManualFeeEnabled &&
              (hasManualFee || shouldShowAddFeeNavigationItem) &&
              !loanLocks?.feesApply,
            icon: <PlusCircleIcon className="w-5 h-5" />,
            label: 'Add a fee',
            onClick: openApplyFeeModal,
          },
          {
            display: shouldShowRateHistoryNavigationItem,
            icon: (
              <SvgIcon
                name="RateHistoryIcon"
                className="w-5 h-5"
                fill="#183f83"
              />
            ),
            label: 'Rate history',
            onClick: openRateHistoryModal,
          },
          {
            display: shouldShowLoanApplicationNavigationItem,
            icon: <SvgIcon name="UnderwritingIcon" className="w-5 h-5" />,
            label: `View ${loanLower} application`,
            onClick: () => {
              router.push(
                ROUTES.UNDERWRITING_REQUEST.replace(
                  ':executionId',
                  loanSummary?.executionId
                )
              );
            },
          },
        ]}
      />
    </BalanceWidgetResolver>
  );
};

export { CurrentBalanceWidget };

type Permissions = 'PAY_OFF/ADD_DOCUMENTATION/VIEW_TRANSACTIONS';

const ROLES_PERMISSIONS: Partial<Record<UserTypes, Permissions[]>> = {
  ORIGINATOR_ADMIN: ['PAY_OFF/ADD_DOCUMENTATION/VIEW_TRANSACTIONS'],
  ORIGINATOR_ANALYST: ['PAY_OFF/ADD_DOCUMENTATION/VIEW_TRANSACTIONS'],
};
