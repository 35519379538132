import { useSearchParams } from 'next/navigation';
import { useGetOwnInvestorsQuery } from '@/services/accounts/investor';
import { useGetExecutionQuery, useGetFlowQuery } from '@/services/underwriting';
import { useUaasContext } from '@/store/uaas';
import { useSelectExecution, useSelectFlow } from '@/store/uaas/selectors';
import { useSelectUser } from '@/store/user/selectors';
import { useRole } from './useRole';

const useBranchEncodedKey = () => {
  const { role } = useRole();

  const user = useSelectUser();
  const storedFlow = useSelectFlow();
  const storedExecution = useSelectExecution();
  const searchParams = useSearchParams();
  const originatorId = searchParams.get('customerId');

  const { flow, execution, isLoading } = useUaasContext();
  // as borrower is assigned only to one originator originatorId used for brand query will be the same for each execution
  // so we can use last executionId to obtain originator data
  const lastExecutionId = user?.borrowerExecutionIds?.at(-1);

  const executionQuery = useGetExecutionQuery(
    {
      id: lastExecutionId ?? execution?.id ?? storedExecution?.id,
    },
    {
      skip:
        role !== 'BORROWER' ||
        (!lastExecutionId && !execution?.id && !storedExecution?.id) ||
        !!originatorId,
    }
  );

  const flowQuery = useGetFlowQuery(
    { flowId: flow?.id ?? storedFlow?.id },
    {
      skip:
        !!lastExecutionId ||
        !!flow?.originatorId ||
        (!storedFlow?.id && !flow?.id),
    }
  );

  const { data: investor, isLoading: isInvestorLoading } =
    useGetOwnInvestorsQuery(undefined, {
      skip: role !== 'INVESTOR',
    });

  return (() => {
    if (role === 'INVESTOR')
      return {
        branchEncodedKey: investor?.originatorEncodedKey || originatorId,
        isLoading: isInvestorLoading,
      };

    if (role === 'BORROWER' && lastExecutionId)
      return {
        branchEncodedKey:
          executionQuery.currentData?.originatorId || originatorId,
        isLoading: executionQuery.isLoading,
      };

    if (role === 'unauthenticated')
      return {
        branchEncodedKey:
          flow?.originatorId ??
          execution?.originatorId ??
          storedExecution?.originatorId ??
          flowQuery?.currentData?.originatorId ??
          executionQuery?.currentData?.originatorId,
        isLoading: isLoading,
      };

    return {
      branchEncodedKey:
        user?.mambuUser?.[0]?.mambuBranchEncodedKey || originatorId,
      isLoading: false,
    };
  })();
};

export { useBranchEncodedKey };
