import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormInput, ToggleSwitch } from 'ui';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';

interface FixedInvestorInterestRateFormProps {
  initialFixedInterestRate: number;
  onFixedInterestRateChange: (value: number | null) => void;
  disabled: boolean;
}

const createFixedInterestRateSchema = yup
  .object({
    isFixedInterestEnabled: yup.boolean(),
    fixedInterestRate: yup
      .number()
      .typeError('Investor interest rate cannot be empty')
      .when('isFixedInterestEnabled', {
        is: true,
        then: (schema) =>
          schema
            .required('Investor interest rate cannot be empty')
            .min(0, 'Interest rate cannot be negative'),
        otherwise: (schema) => schema.notRequired(),
      }),
  })
  .required();

const FixedInvestorInterestRateForm: React.FC<
  FixedInvestorInterestRateFormProps
> = ({ initialFixedInterestRate, onFixedInterestRateChange, disabled }) => {
  const validationSchema = createFixedInterestRateSchema;
  const { loanUpper } = useGetLabelsConfig();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<{ isFixedInterestEnabled: boolean; fixedInterestRate: number }>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      fixedInterestRate: undefined,
      isFixedInterestEnabled: false,
    },
  });

  const onSubmit = (data: {
    isFixedInterestEnabled: boolean;
    fixedInterestRate: number;
  }) => {
    // Call the provided callback with the interest rate or null based on the toggle state
    // null means the feature is turned off
    if (data.isFixedInterestEnabled) {
      onFixedInterestRateChange(data.fixedInterestRate);
    } else {
      onFixedInterestRateChange(null);
    }
  };

  return (
    <div className="h-[108px]">
      <div className="flex justify-between h-full">
        <div className="flex items-center justify-between">
          <p className="heading-200 ml-4 mr-5">
            Apply same rate to all investors
          </p>
          <Controller
            name="isFixedInterestEnabled"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ToggleSwitch
                enabled={value}
                disabled={disabled}
                onChange={(e) => {
                  onChange(e);
                  handleSubmit(onSubmit)();
                }}
              />
            )}
          />
        </div>
        <div className="flex flex-col justify-center">
          <div className="flex items-center justify-end">
            <div className="text-right pr-6">
              <p className="body-300">{loanUpper} initial rate</p>
              <p className="h-10 mt-[5px] leading-10 body-400">{`${initialFixedInterestRate} %`}</p>
            </div>
            <div className="flex-col w-[172px]">
              <p className="body-300">Investor interest rate</p>
              <Controller
                name="fixedInterestRate"
                control={control}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    leftSymbol="%"
                    customLeftPadding="7"
                    type="number"
                    containerClassName="w-[93px]"
                    withSpacing={false}
                    showErrorMessage={false}
                    onChange={(e) => {
                      field.onChange(e);
                      handleSubmit(onSubmit)();
                    }}
                    disabled={disabled || !watch('isFixedInterestEnabled')}
                  />
                )}
              />
            </div>
          </div>
          {errors?.fixedInterestRate && (
            <p className="body-200 text-error-700 mt-2 text-right mr-10">
              {errors?.fixedInterestRate?.message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FixedInvestorInterestRateForm;
