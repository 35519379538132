import { PaidBadge } from '@/components/sections/repayment-schedule';
import { formatAmount, formatDate } from '@/utils/formatters';
import { RepaymentScheduleBalanceSummary } from './RepaymentScheduleBalanceSummary';
import {
  COLUMNS_CHECK,
  COLUMNS_ORDER,
  ColumnName,
  EDIT_COLUMNS_CHECK,
  EditColumnName,
  GROUP_COLUMNS_CHECK,
  GroupColumnName,
  ProductType,
  columnsNames,
  columnsNamesEdit,
  columnsNamesGroup,
} from './repaymentScheduleTable';
import { TableColumnsConfiguration } from 'kennek/interfaces/kennek';
import { Currencies } from 'kennek/interfaces/loans';
import { Cell } from 'ui/types';
import { DisbursementDetails, Installment, Loan } from '@/interfaces/loans';
import { LoanGroup, LoanSummary } from '@/interfaces/loans/queries';

export const checkColumnsType = (
  name: ColumnName | GroupColumnName,
  props: Cell<Installment, any>,
  currency: Currencies
) => {
  if (name === 'dueDate' || name === 'maturityDate') {
    return props?.value ? formatDate(props?.value) : '-';
  } else if (name === 'days') {
    return props?.value;
  } else if (typeof props?.value === 'number') {
    return formatAmount(props?.value, true, currency);
  } else if (name === 'state') {
    return (
      <div className="pl-2 w-full">
        <PaidBadge {...props.row.original} />
      </div>
    );
  } else {
    return props?.value || '-';
  }
};

export const setColumnsOrder = (
  columns: TableColumnsConfiguration<ColumnName>[]
) => {
  return columns
    ?.map((item) => {
      return {
        ...item,
        index: COLUMNS_ORDER[item.name],
      };
    })
    .sort((a, b) => (a.index < b.index ? -1 : 1));
};

export const getColumnGroupView = (
  columns: TableColumnsConfiguration<GroupColumnName>[]
) => {
  const visibleColumns = columns.filter(
    (item) => item.active && GROUP_COLUMNS_CHECK.includes(item.name)
  );
  const columnsData = columnsNamesGroup();

  return visibleColumns.map((item) => {
    return {
      Header: item.name ? columnsData[item.name] : '',
      accessor: item.name,
      id: item.name,
      Cell: (props: Cell<Installment>) => {
        return checkColumnsType(item.name, props, 'GBP'); // For now lack of info about currency from BE
      },
      disableSortBy: true,
      ...getColumnProps(item.name, true),
    };
  });
};

export const getColumnEditView = (
  columns: TableColumnsConfiguration<EditColumnName>[],
  balances: Record<
    string,
    { balance: number; exceeded: number | null; short: number | null }
  >,
  currency: Currencies
) => {
  const visibleColumns = columns.filter(
    (item) => item.active && EDIT_COLUMNS_CHECK.includes(item.name)
  );
  const columnsData = columnsNamesEdit();

  return visibleColumns.map((item) => {
    return {
      Header: item.name ? columnsData[item.name] : '',
      accessor: item.name,
      id: item.name,
      Cell: (props: Cell<Installment>) => {
        return checkColumnsType(item.name, props, 'GBP'); // For now lack of info about currency from BE
      },
      disableSortBy: true,
      ...getColumnProps(item.name, true),
      Footer: () => {
        switch (item.name) {
          case 'number':
            return <div className={'px-2'}>Pending balances:</div>;
          case 'principalDue':
          case 'interestDue':
          case 'feeDue':
            return (
              <div>
                <RepaymentScheduleBalanceSummary
                  pendingBalance={balances[item.name]}
                  currency={currency}
                  key={item.name}
                />
              </div>
            );
          default:
            return null;
        }
      },
      alignRight: false,
    };
  });
};

export const getColumnsView = (
  columns: TableColumnsConfiguration<ColumnName>[],
  productType: ProductType,
  currency: Currencies,
  loan?: Loan
) => {
  const visibleColumns = columns.filter(
    (item) =>
      (item.active && COLUMNS_CHECK.includes(item.name)) ||
      (item.name === 'interestRollUp' &&
        loan?.rollUpDurationLinkedLoanType === 'LINKED')
  );

  const columnsData = columnsNames(productType);

  return visibleColumns.map((item) => {
    return {
      Header: item.name ? columnsData[item.name] : '',
      accessor: item.name,
      id: item.name,
      Cell: (props: Cell<Installment>) => {
        return checkColumnsType(item.name, props, currency);
      },
      ...getColumnProps(item.name),
    };
  });
};

const getColumnProps = (
  columnName: ColumnName | GroupColumnName,
  isGroup = false
) => {
  switch (columnName) {
    case 'number':
      return {
        maxWidth: isGroup ? 40 : 120,
        minWidth: isGroup ? 40 : 120,
      };

    case 'days':
      return {
        maxWidth: 80,
        minWidth: 80,
        alignRight: true,
        disableSortBy: true,
      };

    case 'principal':
    case 'principalDue':
    case 'feeDue':
    case 'feeExpected':
    case 'feePaid':
      return {
        maxWidth: 120,
        minWidth: 120,
        alignRight: true,
      };

    case 'dueDate':
    case 'penaltyDue':
    case 'penaltyExpected':
    case 'totalPaid':
      return {
        maxWidth: 130,
        minWidth: 130,
        alignRight: true,
      };

    case 'principalPaid':
    case 'interestExpected':
    case 'interestDue':
    case 'interestPaid':
      return {
        maxWidth: 140,
        minWidth: 140,
        alignRight: true,
      };

    case 'maturityDate':
    case 'principalExpected':
    case 'defaultInterest':
    case 'totalExpected':
    case 'state':
    case 'disbursement':
      return {
        maxWidth: 150,
        minWidth: 150,
        alignRight: true,
      };

    case 'interestRollUp':
    case 'accruedInterest':
    case 'investorInterest':
      return {
        maxWidth: 170,
        minWidth: 170,
        alignRight: true,
      };

    case 'originatorInterest':
    case 'totalOutstanding':
      return {
        maxWidth: 180,
        minWidth: 180,
        alignRight: true,
      };

    case 'principalOutstanding':
      return {
        maxWidth: 200,
        minWidth: 200,
        alignRight: true,
      };

    case 'totalAccruedInterest':
      return {
        maxWidth: 210,
        minWidth: 210,
        alignRight: true,
      };
  }
};

export const getLoanStartDate = (
  loanSummary: LoanSummary,
  disbursementDetails?: DisbursementDetails
): string => {
  return (
    disbursementDetails?.previewDisbursementDate ??
    disbursementDetails?.expectedDisbursementDate ??
    loanSummary?.disbursementDate
  );
};

/**
 * Get the earliest loan start date of all the loans in `loanGroup`, formatted
 * as an ISO 8601 datetime string. Return `undefined` if there are no loans in
 * the group.
 */
export const getLoanGroupStartDate = (
  loanGroup: LoanGroup
): string | undefined => {
  const loans = loanGroup.details;
  const startDates = loans.map((loan) => getLoanStartDate(loan));
  let earliest = startDates[0];
  for (let index = 1; index < startDates.length; index++) {
    if (startDates[index] < earliest) {
      earliest = startDates[index];
    }
  }
  return earliest;
};
